import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { createPinia } from "pinia";
import ElementPlus from "element-plus";
import 'element-plus/dist/index.css'

import { ConfigProvider } from "vant";
import { Swipe, SwipeItem } from "vant";
import Vant from "vant";
import "./assets/styles/global.css";
// 引入全区路由守卫
import "../permission";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

// createApp(App).use(router).use(ElementPlus,{size:'small',zIndex:3000}).use(createPinia()).mount("#app");
createApp(App)
  .use(router)
  .use(Vant)
  .use(ElementPlus, { size: "small", zIndex: 3000 })
  .use(Swipe)
  .use(SwipeItem)
  .use(createPinia())
  .mount("#app");

import router from "./src/router";
import { detect, getViewportSize } from "./src/utils/device";

router.beforeEach(async (to, from, next) => {
  console.log('页面进入----判断设备--------', getViewportSize())
  console.log('要跳往的页面', to.path)

  // 判断设备
  if (getViewportSize() === "pc") {
    if (to.path === "/pcView") {
      next();
    } else {
      next({
        path: "/pcView",
      });
    }
  } else { // 移动端
    if (to.meta.mobileView) {
      // 如果路由元信息标记为需要展示移动端页面，则正常跳转
      next();
    } else {
      // 否则，重定向到移动端页面
      next({
        path: "/mobileView",
      });
    }
  }
});

import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import { getViewportSize } from '../utils/device';
import Home from "../views/Home.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      title: '首页'
    }
  },
  {
    path: "/pcView",
    name: "PcView",
    component: () => import("../views/page/pc/pcView.vue"),
    meta: {
      title: 'PC端'
    }
  },
  {
    path: "/mobileView",
    name: "MobileView",
    component: () => import("../views/page/mobile/index.vue"),
    meta: {
      title: '移动端',
      mobileView: true // Indicate this route is for mobile view
    },
    redirect:{
      name: 'HomePage'
    },
    children:[
      {
        path: "/mobileView/gameCenter",
        name: "GameCenter",
        component: () => import("../views/page/mobile/gameCenter.vue"),
        meta: {
          title: '游戏中心',
          mobileView: true // Indicate this route is for mobile view
        },
      },
      {
        path: "/mobileView/homePage",
        name: "HomePage",
        component: () => import("../views/page/mobile/homepage.vue"),
        meta: {
          title: '首页',
          mobileView: true // Indicate this route is for mobile view
        },
      }
    ]
  },
 
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  // Check if it's a mobile view and navigate accordingly
  if (getViewportSize() === 'mobile' && !to.meta.mobileView) {
    // Redirect to the default mobile view or another route suitable for your app
    next({ name: 'MobileView' });
  } else {
    next();
  }
});

export default router;

export function detect() {
	var equipmentType = "";
	var agent = navigator.userAgent.toLowerCase();
	// console.log('agent',agent)
	var android = agent.indexOf("android");
	var iphone = agent.indexOf("iphone");
	var ipad = agent.indexOf("ipad");
	if (android != -1) {
		equipmentType = "android";
	}
	if (iphone != -1 || ipad != -1) {
		equipmentType = "ios";
	}
	return equipmentType;
}

export function randomString(e:number) {  
  e = e || 32;
  var t = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678",
  a = t.length,
  n = "";
  for ( let i = 0; i < e; i++) n += t.charAt(Math.floor(Math.random() * a));
  return n
}

// 封装一个函数，获取当前视口的宽高，并根据视口宽高判断设备是移动端还是PC端
export function getViewportSize() {
	var pageWidth = window.innerWidth,
		pageHeight = window.innerHeight;
	if (typeof pageWidth != "number") {
		if (document.compatMode == "CSS1Compat") {
			pageWidth = document.documentElement.clientWidth;
			pageHeight = document.documentElement.clientHeight;
		} else {
			pageWidth = document.body.clientWidth;
			pageHeight = document.body.clientHeight;
		}
	}
	// console.log('pageWidth',pageWidth)
	// console.log('pageHeight',pageHeight)
	if (pageWidth < 768) {
		return "mobile";
	} else {
		return "pc";
	}
}

// 获取是苹果还是安卓
export function detectPhone() {
	var equipmentType = "";
	var agent = navigator.userAgent.toLowerCase();
	var android = agent.indexOf("android");
	var iphone = agent.indexOf("iphone");
	var ipad = agent.indexOf("ipad");
	if (android != -1) {
		equipmentType = "android";
	}
	if (iphone != -1 || ipad != -1) {
		equipmentType = "ios";
	}
	return equipmentType;
}